import {
  CategoryLandingSplitUspBanners,
  CategoryLandingSplitUspBannersWithoutCategoryAndPromotionBanner,
} from '@/modules/categoryLanding/queries/types';
import {
  HomepageSplitUspBanners,
  HomepageSplitUspBannerWithoutCategoryAndPromotionBanner,
} from '@/modules/home/queries/types';
import { publicEnv } from '@lib/env/public-env-vars';

export const mapSplitUsbBanners = (
  banners: HomepageSplitUspBanners | CategoryLandingSplitUspBanners
) =>
  banners
    .filter(isSupportedSplitUspBanner)
    .filter(({ id }) => id)
    .map(addLinkProperty);

const isSupportedSplitUspBanner = (
  banner:
    | HomepageSplitUspBanners[number]
    | CategoryLandingSplitUspBanners[number]
): banner is
  | HomepageSplitUspBannerWithoutCategoryAndPromotionBanner
  | CategoryLandingSplitUspBannersWithoutCategoryAndPromotionBanner =>
  banner.__typename !== 'WebCategorySplitUspBanner' &&
  banner.__typename !== 'WebPromotionSplitUspBanner';

const addLinkProperty = (
  banner:
    | HomepageSplitUspBannerWithoutCategoryAndPromotionBanner
    | CategoryLandingSplitUspBannersWithoutCategoryAndPromotionBanner
) => {
  if ('product' in banner) {
    return {
      ...banner,
      link: `${publicEnv.NEXT_PUBLIC_WEBSITE_URL}/products/${banner.product.slug}`,
    };
  }
  return banner;
};

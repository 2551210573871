import Image from 'next/image';
import Link from 'next/link';
import { ReactNode, useState } from 'react';

import { Button } from '@/core/ui/components/Button/Button';
import { cn } from '@/core/ui/utils';

import { SplitUsbBanners } from '../../types/SplitUsbBanners.type';

type SplitUsbBannersSlideProps = {
  banner: SplitUsbBanners;
};

const Wrapper = ({
  children,
  link,
  className,
}: {
  children: ReactNode;
  link?: string;
  className?: string;
}) =>
  link ? (
    <Link className={className} href={link}>
      {children}
    </Link>
  ) : (
    <div className={className}>{children}</div>
  );

export const SplitUsbBannersSlide = ({ banner }: SplitUsbBannersSlideProps) => {
  const [loading, setLoading] = useState(true);

  const handleLoad = () => {
    setLoading(false);
  };

  return (
    <div
      className={cn(
        'keen-slider__slide',
        'h-[152px]',
        'rounded-xl',
        'md:h-[216px]',
        'overflow-hidden'
      )}
      style={{ backgroundColor: banner.bannerBackgroundColor }}
    >
      <Wrapper
        className={cn('flex', 'justify-between', 'h-full')}
        link={'link' in banner ? banner?.link : undefined}
      >
        <div className={cn('p-4', 'flex', 'flex-col', 'md:py-6')}>
          <h3
            className={cn(
              'text-body-lg',
              'font-medium',
              'mb-1',
              'md:text-title-sm',
              'md:mb-2'
            )}
          >
            {banner.title}
          </h3>
          <p className="text-body-sm">{banner.subtitle}</p>
          <div className="mt-auto">
            {'ctaLabel' in banner && banner?.ctaLabel && (
              <Button size="small">{banner.ctaLabel}</Button>
            )}
          </div>
        </div>
        <div className="relative max-w-[128px] md:max-w-[182px]">
          {loading && (
            <div
              className={cn(
                'absolute inset-0 z-20 size-full animate-pulse rounded-r-xl bg-gray-200'
              )}
            />
          )}
          <Image
            alt={banner.title}
            height={152}
            width={128}
            src={banner.imageUrl}
            className="rounded-r-xl md:hidden"
            onLoad={handleLoad}
          />
          <Image
            alt={banner.title}
            height={216}
            width={182}
            src={banner.imageUrl}
            className="hidden rounded-r-xl md:block"
            onLoad={handleLoad}
          />
        </div>
      </Wrapper>
    </div>
  );
};

import { mapSplitUsbBanners } from '@/core/services/mapSplitUspBanners';
import { SplitUspBanners } from '@/core/ui/components/SplitUspBanners/SplitUspBanners';
import { cn } from '@/core/ui/utils';
import { HomepageSplitUspBannersSection } from '@/modules/home/queries/types';

type SplitUspBannerSectionProps = {
  section: HomepageSplitUspBannersSection;
};

export const SplitUspBannerSection = ({
  section,
}: SplitUspBannerSectionProps) => {
  if (!section?.banners) return null;
  const banners = mapSplitUsbBanners(section.banners);

  return (
    <div className={cn('px-4', 'sm:px-6', 'xl:px-0')}>
      <SplitUspBanners banners={banners} />
    </div>
  );
};

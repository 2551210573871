import { useMemo } from 'react';

import { MOBILE_SLIDE_SPACING } from '@/core/constants/sliders';
import { useSlider } from '@/core/hooks/useSlider';
import { BannersArrows } from '@/core/ui/components/BannersArrows/BannersArrows';
import { BannersDots } from '@/core/ui/components/BannersDots/BannersDots';
import { cn } from '@/core/ui/utils';

import { SplitUsbBannersSlide } from './components/SplitUsbBannersSlide/SplitUsbBannersSlide';
import { SplitUsbBanners as SplitUsbBannersType } from './types/SplitUsbBanners.type';

type SplitUspBannerProps = {
  banners: SplitUsbBannersType[];
};

export const SplitUspBanners = ({ banners }: SplitUspBannerProps) => {
  const durations = useMemo(
    () => banners?.map(({ replaceIn }) => replaceIn),
    [banners]
  );

  const {
    handlePrev,
    handleNext,
    sliderRef,
    handleDotClick,
    currentSlide,
    dotsLength,
    showControls,
    handleMouseLeave,
    handleMouseEnter,
    loaded,
  } = useSlider({
    breakpoints: {
      '(min-width: 1024px)': {
        slides: { perView: 2, spacing: 20 },
      },
      '(min-width: 1280px)': {
        slides: { perView: 3, spacing: 20 },
      },
    },
    slides: {
      perView: 1,
      spacing: MOBILE_SLIDE_SPACING,
      origin: 'center',
    },
    loop: true,
    initial: 0,
    durations,
  });

  return (
    <>
      <div className="relative">
        <div ref={sliderRef} className="keen-slider">
          {!loaded && (
            <div
              className={cn(
                'absolute inset-0 z-20 size-full rounded-xl bg-gray-200'
              )}
            />
          )}
          {banners.map((banner) => (
            <SplitUsbBannersSlide banner={banner} key={banner.id} />
          ))}
        </div>
        {showControls && (
          <BannersArrows
            onPrev={handlePrev}
            onNext={handleNext}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          />
        )}
      </div>
      {showControls && dotsLength && loaded && (
        <BannersDots
          currentSlide={currentSlide}
          totalSlides={dotsLength}
          onClick={handleDotClick}
        />
      )}
    </>
  );
};
